<template>
  <div class="view pa24">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
      <el-form-item label="选择分类" prop="settingId">
        <el-select
          v-model="ruleForm.settingId"
          placeholder="请选择"
          style="width: 400px"
        >
          <el-option
            v-for="item in options"
            :key="item.settingId"
            :label="item.name"
            :value="item.settingId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="需求描述" prop="description">
        <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="ruleForm.description"
          maxlength="200"
          rows="4"
          style="width: 400px"
          show-word-limit
        >
        </el-input>
      </el-form-item>
      <el-form-item label="添加图片">
        <el-upload
          action="https://api.yiliit.com/file/uploadObjectOSS"
          list-type="picture-card"
          :on-progress="handProgress"
          :on-success="handleSuccess"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :file-list="ruleForm.fileList"
          :limit="6"
        >
          <i class="el-icon-plus"></i>
          <div slot="tip" class="el-upload__tip">
            上传图片的最佳尺寸：750像素*422像素，其他尺寸会影响页面效果，格式png，jpeg，jpg。大小不超过2M，最少上传1张最多可上传6张
          </div>
        </el-upload>
        <el-dialog
          :visible.sync="dialogVisible"
          :modal-append-to-body="false"
          :destroy-on-close="true"
        >
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </el-form-item>
      <el-form-item label="备注" prop="note">
        <el-input
          type="text"
          placeholder="请输入内容"
          maxlength="50"
          v-model="ruleForm.note"
          style="width: 400px"
          show-word-limit
        >
        </el-input>
      </el-form-item>
      <el-form-item label="所在位置" prop="address">
        <el-input
          id="suggestId"
          type="text"
          placeholder="请输入地址"
          v-model="ruleForm.address"
          maxlength="50"
          @change="theLocation"
          style="width: 400px"
          show-word-limit
        />
        <div
          id="baidumap"
          style="width: 400px; height: 400px; margin-top: 10px"
        ></div>
      </el-form-item>
      <el-form-item>
        <div class="flex-a-c">
          <el-checkbox v-model="radio"></el-checkbox>
          <a style="margin-left:10px" @click="userAgreement = !userAgreement">我已阅读用户协议</a>
        </div>
        
      </el-form-item>
    </el-form>
    <el-row align="right" style="width: 800px; text-align: right">
      <el-button type="primary" @click="saveForm('1')">保存到草稿</el-button>
      <el-button type="primary" @click="saveForm('0')">发布</el-button>
    </el-row>
    <el-dialog
      title="用户协议"
      :visible.sync="userAgreement"
      append-to-body
      width="50%"
      center>
      <p class="fwbold mb15">本小程序用户的言行不得违反《计算机信息网络国际联网安全保护管理办法》、《互联网信息服务管理办法》、《互联网电子公告服务管理规定》、《维护互联网安 全的决定》等相关法律规定，不得在此发布含有下列内容之一的信息。</p>
      <p class="mb15">1、违反宪法或法律法规规定的；</p>
      <p class="mb15">2、泄露国家秘密，颠覆国家政权，推翻社会主义制度，煽动分裂国家、破坏国家统一的；</p>
      <p class="mb15">3、损害国家荣誉和利益的，损害公共利益的；</p>
      <p class="mb15">4、宣扬恐怖主义、极端主义，煽动民族仇恨、民族歧视，破坏民族团结的；</p>
      <p class="mb15">5、破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
      <p class="mb15">6、散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
      <p class="mb15">7、布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
      <p class="mb15">8、侮辱或者诽谤他人，侵害他人名誉、隐私、知识产权和其他合法权益的；</p>
      <p class="mb15">9、含有法律、行政法规禁止的其他内容的。</p>
      <p class="mb15">10、本小程序认为不利于小程序生态、可能给小程序造成损失的内容。</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="userAgreement = false,radio=false">取 消</el-button>
        <el-button type="primary" @click="userAgreement = false,radio=true">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
let ac;
import {
  queryAllClassification,
  addDemandSquare,
  queryDemandSquare,
  updateDemandSquare,
} from "@/api/addedServices";
export default {
  data() {
    return {
      ruleForm: {
        settingId: "",
        description: "",
        note: "",
        address: "",
        fileList: [],
      },
      rules: {
        settingId: [{ required: true, message: "请选择选择分类" }],
        address: [{ required: true, message: "请输入所在位置" }],
      },
      radio: false,
      options: [],
      dialogImageUrl: "",
      dialogVisible: false,
      userAgreement:false,
      map: {}, //赋值地图
      local: {}, //搜索
    };
  },
  created() {
    const { squareId } = this.$route.query;
    this.queryAllClassification();
    if (squareId) {
      this.queryDemandSquare(squareId);
    }
  },
  mounted() {
    this.createdMap();
  },
  beforeDestroy() {
    ac.removeEventListener("onConfirm", this.setValue);
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    /**@method 创建地图 */
    createdMap() {
      let navigationControl = new BMap.NavigationControl({
        // 靠左上角位置
        anchor: BMAP_ANCHOR_TOP_LEFT,
        // LARGE类型
        type: BMAP_NAVIGATION_CONTROL_LARGE,
        // 启用显示定位
        enableGeolocation: true,
      });
      this.map = new BMap.Map("baidumap");
      this.map.centerAndZoom("北京", 12);
      this.map.addControl(navigationControl);

      ac = new BMap.Autocomplete({
        //建立一个自动完成的对象
        input: "suggestId",
        location: this.map,
      });
      ac.addEventListener("onConfirm", this.setValue);
    },
    setValue(e) {
      const {
        item: { value },
      } = e;
      this.ruleForm.address = `${value.city}${value.district}${value.business}`;
    },
    handProgress(event, file, fileList) {
      this.$message({
        message: "正在上传",
      });
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.fileList = fileList;
    },
    myFun() {
      let pp = this.local.getResults().getPoi(0).point; //获取第一个智能搜索的结果
      this.map.centerAndZoom(pp, 18);
      this.map.addOverlay(new BMap.Marker(pp)); //添加标注
      this.ruleForm.gps = JSON.stringify(pp);
    },
    theLocation(val) {
      this.map.clearOverlays(); //清除地图上所有覆盖物

      this.local = new BMap.LocalSearch(this.map, {
        //智能搜索
        onSearchComplete: this.myFun,
      });
      this.local.search(val);
    },
    /**@method 获取编辑数据 */
    async queryDemandSquare(squareId) {
      const result = await queryDemandSquare({ squareId });
      const {
        settingId,
        description,
        images,
        note,
        address,
        createdUserId,
        companyId,
      } = result.data;
      const imgs = JSON.parse(images);
      this.ruleForm = {
        settingId,
        description,
        fileList: imgs.map((item) => ({
          url: item,
          response: {
            data: item,
          },
        })),
        note,
        address,
        createdUserId,
        companyId,
      };
    },
    /**@method  获取分类*/
    async queryAllClassification() {
      try {
        const result = await queryAllClassification({ dataType: 1 });
        this.options = result?.data;
      } catch (error) {
        console.log(error);
      }
    },
    /**@method 发布 */
    saveForm(val) {
      let than = this
      const { squareId } = this.$route.query;
      let data = {
        ...this.ruleForm,
        source: "web",
        isDraft: val == "0" ? false : true,
        status: 0,
        images: this.ruleForm.fileList.map((item) =>
          item.response.data.indexOf("https") >= 0
            ? item.response.data
            : `https://one-stand-new.oss-cn-shanghai.aliyuncs.com/${item.response.data}`
        ),
      };
      if (!this.radio) {
        this.$message({
          showClose: true,
          message: "请勾选用户协议",
          type: "warning",
        });
        return;
      }
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (squareId) {
            //保存编辑
            await updateDemandSquare(
              { ...data, squareId },
              { showLoading: true }
            );
          } else {
            //添加请求
            addDemandSquare(data, { showLoading: true })
            .then(res=>{
              this.$message({
                type:'success',
                message: '发布成功'
              })
              than.$router.push({ path: "/myNeeds" });
            })
            .catch(err=>{
              if (err.code == 201) {
                than.$message({message:err.message || '发布失败', type:'warning'});
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>